<template>
    <div class="view">
        <div v-if='loading || !orderInfo' class="loadingBox">
            <van-loading type="spinner" color="#1989fa" vertical>加载中...</van-loading>
        </div>
        <van-pull-refresh v-model="refreshLoading" @refresh="refresh" v-else>
            <div class="orderInfo">
                <div class="title">
                    <div class="status">{{ orderInfo.status | filterStatus }}</div>
                    <div class="callPhone" @click="callPhone(1)">
                        <van-icon name="phone-o" size='16'/>
                        <span>致电平台</span>
                    </div>
                </div>
                <div class="line"></div>
                <div class="order">
                    <div class="order_title" @click="order_upIcon = !order_upIcon">
                        <span>订单信息</span>
                        <van-icon name="arrow-down" :class=" order_upIcon ? 'up' : 'down' " />
                    </div>
                    <div v-if='order_upIcon'>
                        <div class="order_line"></div>
                        <div class="info_li">
                            <div>
                                <span>订单号码： </span>
                                <span>{{ orderInfo.no }}</span>
                            </div>
                            <div class="callPhone" @click="onCopy">
                                <van-icon name="records" size='12'/>
                                <span>复制</span>
                            </div>
                        </div>
                        <div class="info_li">
                            <div>
                                <span>下单时间： </span>
                                <span>{{ orderInfo.create_time | filterTime }}</span>
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li">
                            <div>
                                <span>救援类型： </span>
                                <span>{{ orderInfo.type | filterType }}</span>
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li">
                            <div>
                                <span>故障车牌： </span>
                                <span>{{ orderInfo.car_no }}</span>
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li">
                            <div>
                                <span>车主信息： </span>
                                <span>{{ orderInfo.contact | filterContact }}** </span>
                                <span> {{ orderInfo.phone | filterUserPhone }}****</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="order">
                    <div class="order_title" @click="rescue_upIcon = !rescue_upIcon">
                        <span>救援信息</span>
                        <van-icon name="arrow-down" :class=" rescue_upIcon ? 'up' : 'down' " />
                    </div>
                    <div v-if='rescue_upIcon'>
                        <div class="order_line"></div>
                        <div class="info_li">
                            <div>
                                故障车位置：
                            </div>
                            <div class="callPhone" @click="openLocation(1)" v-if='orderInfo.status != 4 && orderInfo.status != 5 && orderInfo.status != 6 && orderInfo.status != 7 && orderInfo.status != 8 && orderInfo.status != 34'>
                                <van-icon name="edit" size='12'/>
                                <span>修改故障车位置</span>
                            </div>
                        </div>
                        <div class="info_li address">
                            <div class="address_text">
                                {{ orderInfo.address ? orderInfo.address : '-' }}
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li" v-if="isDoubleAddress">
                            <div>
                                拖车目的地：
                            </div>
                            <div class="callPhone" @click="openLocation(2)" v-if='orderInfo.status != 4 && orderInfo.status != 5 && orderInfo.status != 6 && orderInfo.status != 7 && orderInfo.status != 8 && orderInfo.status != 34'>
                                <van-icon name="edit" size='12'/>
                                <span>修改拖车目的地</span>
                            </div>
                        </div>
                        <div class="info_li address" v-if="isDoubleAddress">
                            <div class="address_text">
                                {{ orderInfo.destination ? orderInfo.destination : '-' }}
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li" v-if="orderInfo.driver && orderInfo.driver.id">
                            <div>
                                救援技师：
                            </div>
                            <div class="callPhone" @click="callPhone(2)" v-if='orderInfo.status != 4 && orderInfo.status != 5 && orderInfo.status != 6 && orderInfo.status != 7 && orderInfo.status != 8 && orderInfo.status != 34'>
                                <van-icon name="phone-o" size='16'/>
                                <span>致电救援师傅</span>
                            </div>
                        </div>
                        <div class="info_li address" v-if="orderInfo.driver && orderInfo.driver.id">
                            <div class="address_text">
                                <span>{{ orderInfo.driver.nick | filterContact }}师傅 </span>
                                <span> {{ orderInfo.driver.mobile | filterUserPhone }}****</span>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
                <div class="order" v-if="BASEAPI == 'https://v1.api.yijianjiuyuan.com'">
                    <div class="order_title">
                        <span>免责协议</span>
                        <span class="signBtn" @click="toSignFile" v-if="orderInfo.status == 24 || orderInfo.status == 25 || orderInfo.status == 26">
                            <van-icon class="editIcon" name="edit"></van-icon>
                            去签署
                        </span>
                    </div>
                    <div class="siteImgs" v-if="signPhotos.length != 0">
                        <div class="order_line"></div>
                        <van-uploader 
                            result-type='file'
                            :deletable="false"
                            :readonly="false"
                            :show-upload="false"
                            v-model="signPhotos"/>
                    </div>
                </div>
                <div class="order">
                    <div class="order_title">
                        <span>现场照片</span>
                    </div>
                    <div class="siteImgs">
                        <div class="order_line"></div>
                        <van-uploader 
                            upload-text='现场照片'
                            multiple
                            result-type='file'
                            :after-read="siteAfterRead"
                            :before-delete='delSiteImage'
                            v-model="sitePhotos"/>
                    </div>
                </div>
            </div>
        </van-pull-refresh>
    </div>
</template>

<script>
import Data from '@/utils/data.js';
import { getLink, orderDetail, uploadPicture } from '@/apis/checkList';
import { getQueryString, formatTime } from '@/utils/index';
import { putOSS } from '@/utils/uploadOSS';
export default {
    data(){
        return {
            BASEAPI: process.env.VUE_APP_BASE_API,
            order: '',
            loading: false,
            refreshLoading: false,
            orderInfo: '',
            order_upIcon: true,
            rescue_upIcon: true,
            sitePhotos: [],
            signPhotos: [],
            isDoubleAddress: false
        }
    },
    created(){
        // let params = {
        //     order_id: '11083',
        //     type: 'driver'        //owner 车主链接 driver 技师链接
        // }
        // getLink(params).then((res)=>{
        //     console.log(res, '获取案件链接');
        //     // 测试加密参数         TzEwOTU3    TzExMDc4    TzExMDgz
        // })
        this.order = getQueryString() ? getQueryString() : 'TzM|';
        this.loading = true;
        this.getDetail();
    },
    methods: {
        refresh(){
            // 下拉刷新
            console.log('下拉刷新')
            this.getDetail();
        },
        getDetail(){
            let params = {
                order: this.order
            };
            orderDetail(params).then((res)=>{
                this.orderInfo = res.data.data;
                this.isDoubleAddress = this.$jyTypeData(this.orderInfo.type, 'address') == 2
                document.title = this.orderInfo.firm.name;
                console.log(this.orderInfo, '订单详情');
                let picture = this.orderInfo.picture;
                this.sitePhotos = [];
                this.signPhotos = [];
                if(picture && picture.length != 0){
                    picture.forEach((item)=>{
                        if(item.key == 'customer'){
                            if(item.ser == 9){
                                let obj = {
                                    id: item.id,
                                    url: item.doman + item.picture
                                };
                                this.signPhotos.push(obj);
                            }else{
                                let obj = {
                                    id: item.id,
                                    url: item.doman + item.picture
                                };
                                this.sitePhotos.push(obj);
                            }
                        }
                    })
                }
                this.loading = false;
                this.refreshLoading = false;
            })
        },
        // 打电话
        callPhone(index){
            let tel;
            if(index == 1){
                // 平台service_phone
                let service_phone = this.orderInfo.firm.service_phone;
                if(service_phone){
                    tel = `tel:${service_phone}`;
                    window.location.href = tel;
                }else{
                    this.$toast('联系电话错误！');
                    return
                }
            }else{
                // 技师
                let mobile = this.orderInfo.driver && this.orderInfo.driver.mobile ? this.orderInfo.driver.mobile : '';
                if(mobile){
                    tel = `tel:${mobile}`;
                    window.location.href = tel;
                }else{
                    this.$toast('联系电话错误！');
                    return
                }
            }
        },
        // 复制订单号
        onCopy(){
            let text = this.orderInfo.no;
            this.$copyText(text).then(()=>{
                this.$toast('订单号复制成功');
            }).catch(()=>{
                this.$toast('复制失败');
            })
        },
        // 修改地址
        openLocation(index){
            let a_lng, a_lat, e_lng, e_lat;
            a_lng = this.orderInfo.theodolitic.split(',')[0];
            a_lat = this.orderInfo.theodolitic.split(',')[1];
            e_lng = this.orderInfo.transit.split(',')[0];
            e_lat = this.orderInfo.transit.split(',')[1];
            let lng = index == 2 && e_lng ? e_lng : a_lng;
            let lat = index == 2 && e_lat ? e_lat : a_lat;
            let address = index == 2 && this.orderInfo.destination ? this.orderInfo.destination : this.orderInfo.address;
            let city = '';
            this.$router.push({
                path: '/location',
                query: {
                    lng,
                    lat,
                    address,
                    city,
                    type: index,
                    order: this.order
                }
            })
        },
        // 上传现场照片
        async siteAfterRead(file){
            console.log(file, 'file')
            if(Array.isArray(file)){
                // 数组 多张
                file.forEach((e, index)=>{
                    e.status = 'uploading';
                    e.message = '上传中...';
                    putOSS(e.file).then((url)=>{
                        let data = {
                            order: this.order,
                            photo: url
                        }
                        uploadPicture(data).then((res)=>{
                            console.log(res, '上传现场照片');
                            e.status = 'done';
                            e.message = '上传成功';
                            e.id = res.data.data.picture_id;
                            e.url = url;
                        }).catch(()=>{
                            e.status = 'failed';
                            e.message = '上传失败';
                        })
                    })
                });
            }else{
                // 对象
                file.status = 'uploading';
                file.message = '上传中...';
                putOSS(file.file).then((url)=>{
                    let data = {
                        order: this.order,
                        photo: url
                    }
                    uploadPicture(data).then((res)=>{
                        console.log(res, '上传现场照片');
                        file.status = 'done';
                        file.message = '上传成功';
                        file.id = res.data.data.picture_id;
                        file.url = url;
                    }).catch(()=>{
                        file.status = 'failed';
                        file.message = '上传失败';
                    })
                })
            }
        },
        // 删除现场照片
        delSiteImage(file, detail){
            if(file.id){
                let data = {
                    order: this.order,
                    picture_id: file.id
                }
                uploadPicture(data).then((res)=>{
                    if(res.data.code == 1){
                        this.sitePhotos.splice(detail.index, 1);
                        return true;
                    }else{
                        this.$toast(res.data.info);
                    }
                })
            }else{
                return true;
            }
        },
        // 签署免责协议
        toSignFile(){
            this.$router.push({
                name: 'signFile',
                params: {
                    order: this.order,
                    orderInfo: this.orderInfo
                }
            })
        }
    },
    filters: {
        filterStatus(val){
            if(val || val == 0){
				let orderStatus = Data.orderStatus;
				return orderStatus[val];
			}else{
				return '-'
			}
        },
        filterTime(val){
			if(val){
				return formatTime(val)
			}
		},
        filterContact(val){
            if(val){
                return val.substr(0,1);
            }
        },
        filterUserPhone(val){
            if(val){
                let reg = /^(\d+)(\d{7})$/;
                let tel = val.toString().replace(reg, "$1***");
                return tel
            }
        },
    }
}
</script>

<style lang='scss'>
    .siteImgs{
        .van-uploader__preview, .van-uploader__upload {
            width: 160px;
            height: 120px;
            margin: 10px 0 0 0;
            .van-image{
                width: 160px;
                height: 120px;
            }
        }
        .van-uploader{
            width: 100%;
        }
        .van-uploader__wrapper{
            justify-content: space-between;
        }
    }
</style>

<style lang="scss" scoped>
    .view{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        .loadingBox{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .orderInfo{
            min-height: 100%;
            padding: 15px;
            box-sizing: border-box;
            .title{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                font-weight: bold;
                .callPhone{
                    padding: 4px;
                    box-sizing: border-box;
                    border-radius: 2px;
                    display: flex;
                    align-items: center;
                    background: linear-gradient(180deg, #5C9AFE 0%, #357EFE 100%);
                    color: #fff;
                    span{
                        margin-left: 6px;
                    }
                }
            }
            .line{
                border-bottom: 1px solid #EFEFEF;
				margin-top: 15px;
            }
            .order_line{
                border-bottom: 1px solid #EFEFEF;
				margin-top: 10px;
            }
            .order{
                margin-top: 15px;
                font-size: 14px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                background: rgba(255, 255, 255, 0.39);
                border-radius: 4px;
                padding: 15px 10px;
                box-sizing: border-box;
                .order_title{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    span{
                        font-weight: bold;
                    }
                    .up{
                        transition: transform 0.3s;
                        transform: rotateZ(180deg);
                    }
                    .down{
                        transition: transform 0.3s;
                        transform: rotateZ(0deg);
                    }
                    .signBtn{
                        color: #357EFE;
                        display: flex;
                        align-items: center;
                        .editIcon{
                            margin-right: 4px;
                        }
                    }
                }
                .info_li{
                    font-size: 14px;
                    margin-top: 18px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #141414;
                    .callPhone{
                        padding: 3px 4px;
                        box-sizing: border-box;
                        border-radius: 2px;
                        display: flex;
                        align-items: center;
                        background: linear-gradient(180deg, #5C9AFE 0%, #357EFE 100%);
                        color: #fff;
                        span{
                            margin-left: 6px;
                        }
                    }
                }
                .address{
                    margin-top: 10px;
                    z-index: 9;
                    .address_text{
                        line-height: 20px;
                    }
                }
            }
        }
    }
</style>
